
import Forms from "./forms/index";
import SubHeader from "@/components/SubHeader"
import taxDetails from "@/assets/icons/svg/tax-details.svg"
import assignTenant from "@/assets/icons/svg/assign-tenant.svg"
import { getTaxDetails } from "../services";
export default  {
    name: "creditPack",
    data: ()=>{
        return {
            rate: null,
            taxOptions: [
                {
                    name: "tax_details",
                    label: "Tax details",
                    values: {},
                    hasErrors: false,
                    disabled: false,
                    show: true,
                    logo: taxDetails
                },
                {
                    name: "tenant_assign",
                    label: "Assign Tenants",
                    values: {},
                    hasErrors: false,
                    disabled: true,
                    show: true,
                    logo: assignTenant

                },
            ],
            selectedSection: "tax_details",
            FormComponents: Forms
        }
    },
    components: {
        SubHeader
    },
    mounted() {
        if (this.$route.params.id) {
            this.taxOptions.forEach((res)=>{
                res.disabled = false
            })
        }
    },
    computed: {
        activeForm: {
            get() {
                return this.taxOptions.find((el) => el.name === this.selectedSection);
            },
            set(val) {
                console.log(val, ">>>val");
            },
        },
    },
    methods: {
        async sectionClick(tabData) {
            this.selectedSection = tabData.name
            const {data} = await getTaxDetails(this.$route.params.id)
            this.$router.push({query: { rate: data.rate }});
        },
        async handleNext(taxData) {
            this.selectedSection = taxData.nextSection
            this.rate = taxData.taxRate
            this.$router.push({params:{id: taxData.taxId}, query: { rate: taxData.taxRate }});
            this.taxOptions.forEach((res)=>{
                if (res.name == taxData.nextSection) {
                    res.disabled = false
                }
            });
        }
    }
}
<template>
  <div>
    <div class="p-1">
      <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col">
        <div
          class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl"
        >
          Assign Tax
        </div>
          <div
          class="bg-white p-6 rounded-lg py-24 flex items-center justify-center"
          v-if="loadingTaxes"
        >
          <Loader />
        </div>
        <div class="py-3 px-6" v-else>
          <custom-table selectAll searchBar :columns="columns" :rows="rows" :handleSelectAll="handleSelect">
            <template v-slot:title="props">
              <div class="flex items-center gap-2 ml-4">
              <input 
                :key="props.item.id"
                type="checkbox" 
                :checked="props.item.tax_assigned" 
                v-model="props.item.tax_assigned"
                class="checkbox checkbox-sm checkbox-primary" 
                @change="reArrange"
              /> 
              <span>{{ props.item.title }}</span>
              </div>
            </template>
            <template v-slot:rate="props">
              <input
                :disabled="handleDisable(props.item)"
                type="text"
                placeholder="Enter Rate"
                class="w-14 rounded-lg text-center p-1 border"
                :class="handleDisable(props.item) ? 'cursor-not-allowed' : ''"
                v-model="props.item.rate"
                @change="saveTaxRate(props.item)"
                @input="handleValidRate(props.item)"
              />
              </template>
              <template v-slot:default="props">
                <input
                  type="checkbox"
                  :checked="props.item.default"
                  v-model="props.item.default"
                  class="checkbox checkbox-sm checkbox-primary mt-1"
                  @change="defaultTax(props.item)"
                />
              </template>
          </custom-table>
          <div class="flex justify-end max-w-full">
              <Button
                :disabled="!changeMade"
                @click="assignTax"
                class="btn-primary float-right mt-4"
                text="Save"
              />
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customTable from "@shared/components/custom-table";
import {getTenantTax,assignTaxTenant, getTaxDetails} from "../../services"
import Button from "@/components/button";
import Loader from "@shared/loader";
export default {
  name: "taxTenantAssign",
  data: () => {
    return {
      changeMade: false,
      columns: [
        { name: "Tenant Name", label: "title", type: "custom" },
        { name: "Rate(%)", label: "rate", type: "custom" },
        { name: "Default", label: "default", type: "custom" },
      ],
      rows: [],
      savedTax: [],
      taxDetails: {},
      loadingTaxes: false
    };
  },
  props: {
    rate: {
      type: Number
    }

  },
  components: { customTable, Button, Loader },
  async mounted() {
      const {data} = await getTaxDetails(this.$route.params.id)
      this.taxDetails = data
      this.loadingTaxes = true
      await this.setTenantTax()
      this.savedTax = JSON.parse(JSON.stringify(this.rows))
      this.loadingTaxes = false
  },
  watch : {
    rows: {
      deep: true,
      handler() {
        if (!this.loadingTaxes) {
          this.changeMade = true
        }
      }
    },
    rate: {
      deep: true,
      async handler(){
        const {data} = await getTaxDetails(this.$route.params.id)
        this.taxDetails = data
        this.loadingTaxes = true
        await this.setTenantTax()
        this.loadingTaxes = false
      }
    }
  },
  methods: {
    reArrange() {
      let selectedArr = [];
      let unSelectedArr = [];
      this.rows.forEach((item)=> {
        if(item.tax_assigned) {
          selectedArr.push(item)
        }
        else {
          unSelectedArr.push(item)
        }
      })
      this.rows = [...selectedArr, ...unSelectedArr]
    },
    saveTaxRate(taxValue) {
      if (taxValue.rate > 100) {
        taxValue.rate = taxValue.validRate 
      } else if (taxValue.rate <= 100) {
        taxValue.validRate = taxValue.rate
        this.savedTax.forEach((res)=>{
          if (res.id == taxValue.id) {
            res.rate = taxValue.rate
          }
        })
      }
    },
    handleValidRate(rateValue) {
      if (rateValue.rate > 100) {
        rateValue.rate = rateValue.validRate 
      } else if (rateValue.rate <= 100) {
        rateValue.validRate = rateValue.rate
        this.savedTax.forEach((res)=>{
          if (res.id == rateValue.id) {
            res.rate = rateValue.rate
          }
        })
      }
    },
    handleDisable(taxValue) {
      return taxValue?.default;
    },
    async setTenantTax() {
      const {data} = await getTenantTax(this.$route.params.id);
      this.rows = data.data;
      this.rows.forEach((res)=> {
        res.tax_id= this.$route.params.id
        if (res.default) {
          res.rate = this.taxDetails.rate
          res.validRate = this.taxDetails.rate
        }
      })
      this.reArrange()
    },
    defaultTax(tax) {
      if(tax.default){
        tax.rate = this.taxDetails.rate;
      }
      else {
        const defaultRate = this.savedTax.find((res)=> res.id == tax.id);
        tax.rate = defaultRate.rate;
      }
    },
    async assignTax() {
      try {
        this.changeMade = false
        await assignTaxTenant(this.rows);
        this.$toast.success('Tenants tax details updated');
      } catch(error) {
        this.$toast.error(error?.response?.data?.detail || 'Something went wrong!');
        console.log('error', error);
      }
    },
    handleSelect(selectedAll) {
      if (selectedAll) {
        this.rows.forEach((res) => {
          res.tax_assigned = true;
        });
      } else {
        this.rows.forEach((res) => {
          res.tax_assigned = false;
        });
      }
      this.changeMade = true;
    }
  },
};
</script>

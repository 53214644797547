var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SubHeader',{attrs:{"buttonText":"Create Tax","handleClick":_vm.createTax,"showBtn":true}}),_c('div',{staticClass:"flex relative flex-1 flex-col flex-grow max-h-full max-w-full"},[_c('vue-good-table',_vm._b({attrs:{"isLoading":_vm.loadingTaxes,"fixed-header":false,"columns":_vm.computedColumns,"rows":_vm.rowsData,"pagination-options":{
        enabled: true,
        mode: 'records',
        dropdownAllowAll: false,
      },"totalRows":_vm.totalRows},on:{"on-column-filter":_vm.onColumnFilter,"on-per-page-change":function($event){return _vm.onPageChange($event, true)},"on-page-change":function($event){return _vm.onPageChange($event)},"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"column-filter",fn:function({ column, updateFilters }){return [(
            column.filterOptions &&
            column.filterOptions.enabled &&
            column.config &&
            !['indicators', 'allocation'].includes(column.config.type)
          )?_c('dropdown',{ref:_vm.setFilterRef,staticClass:"filter-dropdown",attrs:{"reposition":"","searchable":"","placeholder":"Select","options":column.filterOptions.filterDropdownItems,"value":_vm.getFilterValue(column),"limit":1,"maxWidth":"100%","width":"100px","minWidth":"100%","config":{ label: 'name', trackBy: 'id' },"multiple":false,"taggable":false},on:{"input":(value) => _vm.updateColumnFilters(column, value, updateFilters),"search":function($event){return _vm.searchFilter(column, $event)}},scopedSlots:_vm._u([{key:"caret",fn:function(){return [_c('sort-icon',{staticClass:"fill-current"})]},proxy:true}],null,true)}):_c('div',{})]}},{key:"table-row",fn:function(props){return [(props.column.field == 'rate')?_c('div',{staticClass:"flex justify-center"},[_vm._v(" "+_vm._s(props.row.rate)+" ")]):_vm._e(),(props.column.field === 'tax_name')?_c('div',{staticClass:"flex gap-3 pl-3",class:[props.row.system_default && 'default hover-text']},[_c('router-link',{staticClass:"text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron",attrs:{"to":{
              name: 'taxInfo',
              params: { id: props.row.id },
            }}},[_vm._v(_vm._s(props.row.tax_name))])],1):_vm._e(),(props.column.field == 'tenants')?_c('div',{staticClass:"flex justify-center cursor-pointer",on:{"click":function($event){return _vm.assignedTenants(props.row.tenants)}}},[_c('div',{staticClass:"indicator"},[_c('span',{staticClass:"w-2 h-4 indicator-item badge badge-primary"},[_vm._v(_vm._s(props.row.tenants.length))]),_c('font-awesome-icon',{staticClass:"w-6 mx-2",attrs:{"icon":"user"}})],1)]):_vm._e()]}},{key:"loadingContent",fn:function(){return [_c('div',{staticClass:"w-full py-10 justify-center flex"},[_c('Loader')],1)]},proxy:true}])},'vue-good-table',_vm.config,false)),(_vm.isFilters)?_c('button',{staticClass:"cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute left-2 z-50",class:_vm.isSelectionChanged ? 'top-14' : 'top-2',on:{"click":_vm.clearFilters}},[_vm._v(" Clear filters ")]):_vm._e()],1),_c('Modal',{ref:"assigned-tenants",attrs:{"title":"Assigned Tenants","showFooter":false},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"grid gap-2"},_vm._l((_vm.tenantsList),function(data){return _c('span',{key:data,staticClass:"border-b text-bold"},[_vm._v(_vm._s(data.title))])}),0)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from "@/axios"


export async function getTaxList(params) {
    const url = `/tax?${params}`
    return await axios.get(url)
}

export async function saveTax(payload) {
    const url = '/tax'
    return await axios.post(url, payload)
}

export async function getTaxDetails(id) {
    const url = `/tax/${id}`
    return await axios.get(url)
}

export async function editTaxDetails(payload) {
    try {
        const url = `/tax`
        return await axios.put(url,payload);
    } catch (error) {
        console.log('error', error);
    }
}

export async function getTenantTax(taxId) {
    const url = `/tax/tenant-taxes/${taxId}`
    return await axios.get(url)
}

export async function assignTaxTenant(payload) {
    try {
        const url = "/tax/assign-tax"
        return await axios.post(url , payload);
    } catch (error) {
        console.log('error', error);
    }
}

export const validNumber = (value) => {
    // eslint-disable-next-line no-useless-escape
    const numValue = /^-?(\d+(\.\d*)?|\.\d+)$/;
    return numValue.test(value);
};

export const vildateTaxName = (value) => {
    // eslint-disable-next-line no-useless-escape
    const validTaxName = /^[A-Za-z][A-Za-z0-9_\-() ]{1,255}$/;
    return validTaxName.test(value);
};
